import { api, getDateToSend } from "../../../../shared/api";
import {
  dateColumnBody,
  getFirsDayOfMonthDateObj,
  getLastDayOfMonthDateObj,
} from "../../../../shared/lib";
import { vacationRequestStatusesIris } from "../../../../entities/vacationRequestStatusesIris";
import { itemsPerPageForAllItems } from "../../../../entities/itemsPerPageForAllItems";

export const checkPeriodsForUpdatingRequest = async (fData) => {
  console.log(fData, "fData");

  const vacationRequestsRequestResponse = await api.vacations.get({
    employee: fData.employee,
    "status[]": [
      vacationRequestStatusesIris.waiting,
      vacationRequestStatusesIris.planned,
      vacationRequestStatusesIris.accepted,
    ],
    "dateFrom[after]": getDateToSend(
      getFirsDayOfMonthDateObj(fData.plannedVacationDatesArr[0])
    ),
    "dateTo[before]": getDateToSend(
      getLastDayOfMonthDateObj(fData.plannedVacationDatesArr[1])
    ),
    itemsPerPage: itemsPerPageForAllItems,
  });

  const { warning, error } = vacationRequestsRequestResponse;

  if (warning || error) return vacationRequestsRequestResponse;

  const vacationRequestsArr = vacationRequestsRequestResponse["hydra:member"];

  const blockingPeriodRequestObj = vacationRequestsArr.find((requestObj) => {
    if (requestObj["@id"] === fData["@id"]) return false;

    const requestObjDateFromDateObj = new Date(requestObj.dateFrom);
    const requestObjDateToDateObj = new Date(requestObj.dateTo);

    if (
      requestObjDateFromDateObj.getTime() >=
        fData.plannedVacationDatesArr[0].getTime() &&
      requestObjDateFromDateObj.getTime() <=
        fData.plannedVacationDatesArr[1].getTime()
    ) {
      return true;
    }

    if (
      requestObjDateToDateObj.getTime() >=
        fData.plannedVacationDatesArr[0].getTime() &&
      requestObjDateToDateObj.getTime() <=
        fData.plannedVacationDatesArr[1].getTime()
    ) {
      return true;
    }
    return false;
  });

  if (blockingPeriodRequestObj) {
    return {
      warning: {
        title: "Drogi Użytkowniku!",
        message: `Wniosek został już złożony w przedziale czasowym od ${dateColumnBody(
          blockingPeriodRequestObj.dateFrom
        )} do ${dateColumnBody(blockingPeriodRequestObj.dateTo)} `,
      },
    };
  }
};
