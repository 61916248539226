import { useTranslation } from "react-i18next";
import logo from "../../../../shared/lib/img/Logo_BeUpHR.png";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { PageHeader } from "../../../../shared/PageHeader";
import { AbsolutePlatform } from "../../../../shared/AbsolutePlatform";
import { PageFooter } from "../../../../shared/PageFooter";
import { LogInForm } from "../../../../features/form";

export const LogIn = ({ currentUserState }) => {
  const { t, i18n } = useTranslation();
  const toastRef = useRef(null);

  return (
    <>
      <Toast ref={toastRef} />
      <AbsolutePlatform
        maxWidth={550}
        secondClassName="page logIn_page container_local"
      >
        <PageHeader title={t("pages.logIn.header.title")}>
          <img src={logo} alt="BeUp HR" className="logo" />
        </PageHeader>

        <div className="page_content">
          <LogInForm
            i18n={i18n}
            toastRef={toastRef}
            currentUserState={currentUserState}
          />
        </div>

        <PageFooter />
      </AbsolutePlatform>
    </>
  );
};
