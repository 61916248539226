export const initValues = {
  name: "",
  surname: "",
  email: "",
  department: null,
  company: null,
  user: null,
  supervisor: null,
  additionalDepartments: [],
};
