
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { FieldError } from "../../../../../../shared/FieldError";
import { loadingProps } from "../../../../../../entities/loadingProps";
import { Password } from 'primereact/password';
import { EmployeeDropdown } from "../../../../../../shared/EmployeeDropdown";
import { useEffect, useMemo } from "react";
import { getRolesSelectOptions } from "../lib";

export const ActionUser = ({formState,currentUser,i18n,toastRef,electedEmployee,actionType,onSubmit,loading}) => {

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = formState

  const { t } = i18n;							

  const rolesSelectOptions = useMemo(()=>getRolesSelectOptions(currentUser,i18n),[])

  useEffect(()=>{
    if(electedEmployee && electedEmployee?.email)setValue("email",electedEmployee.email)
  },[electedEmployee])

  return (
    <form onSubmit={handleSubmit((fData)=>onSubmit(fData))}>
      <div className="form_fields_grid">

        <label className="align_self_center">{t("repeatedWords.employee")} *</label>
        <div className="form_input_container">
        <Controller name="employee" control={control} rules={{required:true}} render={({field})=><EmployeeDropdown currentUser={currentUser} i18n={i18n} toastRef={toastRef} dropDownProps={{value:field.value,onChange:(e) =>{field.onChange(e.value)}}} /> }/>
        <FieldError fieldErr={errors?.employee}/>
        </div>

        <label className="align_self_center">Email *</label>
        <div className="form_input_container">
          <Controller name="email" control={control} rules={{required:true,pattern:/^[\w\.-]{1,64}@[a-zA-Z\d\.-]{2,}\.[a-zA-Z]{2,}$/}} render={({field})=><InputText maxLength={120} placeholder={`${t("repeatedWords.enter")} Email`} value={field.value} onChange={(e) => field.onChange(e.target.value)} />}/>
          <FieldError fieldErr={errors?.email} errPatternMessage={t("error.user.pattern.message")}/>
        </div>

        <label className="align_self_center">{t("repeatedWords.roles")} *</label>
        <div className="form_input_container">
        <Controller name="roles" control={control} rules={{required:true}} render={({field})=><Dropdown placeholder={`${t("repeatedWords.choose")} ${t("repeatedWords.roles")}`} options={rolesSelectOptions} value={field?.value} onChange={(e) =>{field.onChange(e.value)}}/> }/>
        <FieldError fieldErr={errors?.roles}/>
        </div>

        {actionType==="add" && <><label className="align_self_center">{t("pages.logIn.form.fields.password.label")} *</label>
        <div className="form_input_container">
          <Controller name="password" control={control} rules={{required:true}} render={({field})=><Password className="dialog_password_field" maxLength={50} placeholder={`${t("repeatedWords.enter")} ${t("pages.logIn.form.fields.password.label")}`} value={field.value} onChange={(e) => field.onChange(e.target.value)} toggleMask />}/>
          <FieldError fieldErr={errors?.password}/>
        </div></>}


        <Button className="submit_button" label={actionType==="update"?t("repeatedWords.update"):t("pages.vacations.createRequest.NewVacationRequestForm.submitButton")} {...(loading?loadingProps:{})}/>

      </div>
    </form>
  );
};
