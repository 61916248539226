import { useState } from "react";
import { Action as UI } from "./ui/Action";
import { api, prepareEntityToSendById, removeEmptyKeys } from "../../../../../../../shared/api";
import { toastMessageConfig } from "../../../../../../../entities/toastMessageConfig";
import { useForm } from "react-hook-form";
import { initValues } from "./lib";

export const Action = ({i18n,toastRef,dialogState,employee,vacationTypes,defaultValues = {},actionType = "add",tableState}) => {
  
  const [componentStateValue, setComponentStateValue] = useState({ status: "waiting" });

  const { t } = i18n

  const formState = useForm({ mode: "onChange",defaultValues:{...initValues,...defaultValues}});

  const [, setDialogStateValue] = dialogState;

  const onSubmit = (employee) => async (fData) => {
 
    setComponentStateValue({ status: "loading" });

    fData.Employee = employee

    prepareEntityToSendById(fData,"Employee")

    removeEmptyKeys(fData)

    const actionLimitResponse = await (actionType === "add"?api.vacationLimits.post(fData):api.vacationLimits.put(fData.id, fData));

    setDialogStateValue({ visibility: false });

    const { warning , error } = actionLimitResponse

    if(warning){
      toastRef.current.show({ severity: 'warn', summary: warning.title, detail: warning.message,life:toastMessageConfig.life})
      return
    }

    if(error){
      toastRef.current.show({ severity: 'error', summary: error.title, detail: error.message,sticky:true })
      return
    }

    toastRef.current.show({ severity: 'success', summary: t("repeatedWords.success"), detail: t("message.successfulOperation.message"),life:toastMessageConfig.life})
    
    const [,setTableStateValue] = tableState
    setTableStateValue(prevState=>({...prevState,refreshesNumber:prevState.refreshesNumber + 1}))

  };

  if(componentStateValue.status === "waiting"){ 
    return <UI i18n={i18n} formState={formState} actionType={actionType} onSubmit={onSubmit(employee)} vacationTypes={vacationTypes}/>
  }

  if(componentStateValue.status === "loading"){ 
    return <UI i18n={i18n} formState={formState} actionType={actionType} onSubmit={onSubmit(employee)} vacationTypes={vacationTypes} loading/>
  }

};
