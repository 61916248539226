import "./style.css";
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { checkDateRangeCompleteness, getAttachmentButtonPropsByField, getDaysBetweenDates,isFieldDisabled } from "../lib"
import { FieldError } from "../../../../shared/FieldError";
import { loadingProps } from "../../../../entities/loadingProps"
import { useEffect, useMemo, useState } from "react";
import { getFieldsConfigByAccess } from "../lib/getFieldsConfigByAccess";
import { EmployeeDropdown } from "../../../../shared/EmployeeDropdown"
import { Dialog } from 'primereact/dialog';
import { AdvancedFileUpload } from "../../../../shared/AdvancedFileUpload";
import { getCachedDialogVisibilityProps, getCross, getSelectFieldValue } from "../../../../shared/lib";

export const ActionVacationRequest = ({ formState,defaultValuesFormCopy,i18n,toastRef,currentUser,className,type = "new", onSubmit,electedEmployee,loading = false }) => {
  
  const {
    watch,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = formState

  const { t } = i18n;			

  const typesOfRequestOptions = useMemo(()=>(electedEmployee && electedEmployee?.vacationLimits?.length)?electedEmployee.vacationLimits.map(limit=>limit.vacationType):[],[electedEmployee]) 

  const fieldsConfigByAccess = useMemo(()=>getFieldsConfigByAccess(currentUser,defaultValuesFormCopy,type),[])
  useEffect(()=>{if(fieldsConfigByAccess?.employee)setValue("employee",fieldsConfigByAccess.employee.value)},[])

  const plannedVacationDatesArrFieldValue = watch("plannedVacationDatesArr");

  const daysBetweenVacationDates = useMemo(()=>getDaysBetweenDates(plannedVacationDatesArrFieldValue),[plannedVacationDatesArrFieldValue]) 

  const addAttachmentDialogState = useState({visible:false});
  const [addAttachmentDialogStateValue, setAddAttachmentDialogStateValue] = addAttachmentDialogState

  useEffect(()=>checkDateRangeCompleteness(formState,i18n,plannedVacationDatesArrFieldValue),[plannedVacationDatesArrFieldValue])

  return (
    <form className={className?`NewVacationRequestForm ${className}`:"NewVacationRequestForm"} onSubmit={handleSubmit((fData)=>onSubmit(fData))}>
      <h3 className="NewVacationRequestForm_title">
        {type==="update"?t("pages.vacations.createRequest.NewVacationRequestForm.updateTitle"):t("pages.vacations.createRequest.NewVacationRequestForm.title")}
      </h3>

      <Dialog header={t("repeatedSentences.addAttachment")} closeOnEscape={false} className="dialog" visible {...getCachedDialogVisibilityProps(addAttachmentDialogStateValue)} onHide={() => setAddAttachmentDialogStateValue({visible:false})} >
        <Controller name="attachment" control={control} render={({field})=><AdvancedFileUpload i18n={i18n} maxFileSize={2097152} accept={".pdf"} onChange={(e) =>field.onChange(e.files)}/>}/>
      </Dialog>

      <div className="form_fields_grid">

        <label className="align_self_center">{t("pages.vacations.createRequest.NewVacationRequestForm.fields.nameAndSurname.label")}</label>
        <div className="form_input_container">
        <Controller name="employee" control={control} rules={{required:true}} render={({field})=><EmployeeDropdown toastRef={toastRef} i18n={i18n} currentUser={currentUser} dropDownProps={{...{disabled:fieldsConfigByAccess?.employee?.disabled},...isFieldDisabled(type),value:field?.value,onChange:(e) => field.onChange(e.value),focusInputRef:field.ref}}/> }/>
        <FieldError fieldErr={errors.employee}/>
        </div>

        <label>{t("pages.vacations.createRequest.NewVacationRequestForm.fields.positionDepartment.label")}</label>
        <p className="beUpColor">{electedEmployee && electedEmployee.department.name}</p>

        <label className="align_self_center">{t("pages.vacations.createRequest.NewVacationRequestForm.fields.typeOfRequest.label")}</label>
        <div className="form_input_container">
        <Controller name="type" control={control} rules={{required:true}} render={({field})=><Dropdown {...(fieldsConfigByAccess?.type)?fieldsConfigByAccess.type:{}} placeholder={`${t("repeatedWords.choose")} ${t("pages.vacations.createRequest.NewVacationRequestForm.fields.typeOfRequest.label")}`} optionLabel={"name"} optionValue={"@id"} options={typesOfRequestOptions} value={field.value}  focusInputRef={field.ref} onChange={(e) => field.onChange(e.value)}/> }/>
        <FieldError fieldErr={errors.type}/>
        </div>

        <label className="align_self_center">{t("pages.vacations.createRequest.NewVacationRequestForm.fields.plannedVacation.label")}</label>
        <div className="form_input_container">
        <Controller name="plannedVacationDatesArr" control={control} rules={{required:true}} render={({field})=>
          <Calendar value={field.value} onChange={(e) => {field.onChange(e.value)}} selectionMode="range" readOnlyInput 
            showIcon showButtonBar 
            placeholder={t("pages.vacations.createRequest.NewVacationRequestForm.fields.plannedVacation.placeholder")}
            {...(fieldsConfigByAccess?.plannedVacationDatesArr)?fieldsConfigByAccess.plannedVacationDatesArr:{}}
          />}
        />
        <FieldError fieldErr={errors.customErrorForPlannedVacationDatesArr}/>
        <FieldError fieldErr={errors.plannedVacationDatesArr}/>
        </div>

        <label className="align_self_center">{t("pages.vacations.createRequest.NewVacationRequestForm.fields.vacationDaysNumber.label")}</label>
        <InputText value={daysBetweenVacationDates} className="NewVacationRequestForm_input_vacationDaysNumber beUpColor" disabled/>

        <label className="align_self_center">{t("pages.vacations.createRequest.NewVacationRequestForm.fields.replacement.label")}</label>
        <div className="form_input_container">
        <Controller name="replacement" control={control} render={({field})=><EmployeeDropdown toastRef={toastRef} i18n={i18n} currentUser={currentUser} dropDownProps={{value:field?.value,onChange:(e) => field.onChange(getSelectFieldValue(e.value)), ...getCross(field?.value)}}/> }/>
        <FieldError fieldErr={errors.replacement}/>
        </div>

        <label>{t("repeatedWords.attachment")}</label>
        <div className="form_input_container">
        <Controller name="attachment" control={control} render={({field})=><Button {...(fieldsConfigByAccess?.attachment)?fieldsConfigByAccess.attachment:{}} type="button" icon="pi pi-plus" outlined {...getAttachmentButtonPropsByField(field,t)} onClick={()=>setAddAttachmentDialogStateValue({visible:true})}/>}/>
        <FieldError fieldErr={errors.attachment}/>
        </div>

        <label>{t("pages.vacations.createRequest.NewVacationRequestForm.fields.comment.label")}</label>
        <Controller name="comment" control={control} render={({field})=><InputTextarea {...(fieldsConfigByAccess?.comment)?fieldsConfigByAccess.comment:{}} {...field} autoResize rows={5} cols={30} maxLength={200} placeholder={t("pages.vacations.createRequest.NewVacationRequestForm.fields.comment.placeholder")}/> }/>

        <Button className="submit_button" label={type==="update"?t("repeatedWords.update"):t("pages.vacations.createRequest.NewVacationRequestForm.submitButton")} {...(loading?loadingProps:{})}/>
      </div>
    </form>
  );
};
