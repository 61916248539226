import { authTokenName } from "../../../entities/authTokenName"
import { mainPath } from "./mainPath"

export const getVacationRequestAttachmentUrlString = (vacationId,params) =>{
    const url = new URL(`${mainPath}/vacation/${String(vacationId)}/file/`)
  
    url.searchParams.append("auth",sessionStorage.getItem(authTokenName).replace('Bearer ', ''))
    
    if(params) for (const key in params) url.searchParams.append(key, params[key]);
    
   return url.toString()
  }
