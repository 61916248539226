import "./index.css";
import { lazy, Suspense, useEffect} from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AbsolutePlatform } from "../shared/AbsolutePlatform";
import { SideBar } from "../features/SideBar";
import { Loading } from "../shared/Loading/ui/Loading";
import { NotFound } from "../pages/NotFound/";
import { useLocation } from "react-router-dom";
import { LogIn as LogInPage } from "../pages/LogIn/index";
import { authTokenName } from "../entities/authTokenName"
import { initialProjectDirectory } from "../entities/initialProjectDirectory";
import { api } from "../shared/api";
import { useState } from "react";
import { logOut } from "../shared/lib";
import { ResetPassword as LogInResetPasswordPage } from "../pages/LogIn/ResetPassword";

const HomePage = lazy(() => import("../pages/Home"));

// vacations/
const CreatesVacationsRequestPage = lazy(()=>import("../pages/vacations/CreateRequest"))
const ListOfVacationRequestsPage = lazy(()=>import("../pages/vacations/ListOfRequests") )
// const VacationsReportsPage = lazy(()=>import("../pages/vacations/Reports") )
const VacationsCalendarPage = lazy(()=>import("../pages/vacations/Calendar") )

// settings/
const SettingsOrganizationPage = lazy(()=>import("../pages/settings/organization/index/"))
// setting/ organization
const SettingsOrganizationAddEmployeePage = lazy(()=>import("../pages/settings/organization/AddEmployee/"))
const SettingsOrganizationNotificationsPage = lazy(()=>import("../pages/settings/organization/Notifications/"))


// // setting/ organization / vacations
const SettingsOrganizationVacationsLimitsPage = lazy(()=>import("../pages/settings/organization/vacations/Limits"))

export const App = () => {
  const location = useLocation()
  const navigate = useNavigate();

  const authToken = sessionStorage.getItem(authTokenName)

  const currentUserState = useState(undefined)
  const [currentUser,setCurrentUser] = currentUserState

  useEffect(()=>{if(!authToken && !location.pathname.includes(`${initialProjectDirectory}/logIn`))navigate(`${initialProjectDirectory}/logIn`)})

  useEffect(()=>{
    if(authToken && !currentUser){
    (async()=>{
          const userResponse = await api.users.getCurrent()

          const { warning, error } = userResponse;

          if (error || warning) {
            logOut(navigate)
            return;
          }
          setCurrentUser(userResponse);
    })()
  }
})

  if( authToken && !currentUser ) return <Loading/>

  if(authToken) return<AbsolutePlatform maxWidth={1600} secondClassName="global_content_container">
    <SideBar navigate={navigate} currentUser={currentUser}/>
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route exact path={`${initialProjectDirectory}/`} element={<HomePage/>}></Route>
        <Route exact path={`${initialProjectDirectory}/home`} element={<HomePage />} default></Route>

        <Route exact path={`${initialProjectDirectory}/vacations/`} element={<ListOfVacationRequestsPage currentUser={currentUser} />} ></Route>
        <Route exact path={`${initialProjectDirectory}/vacations/createRequest`} element={<CreatesVacationsRequestPage currentUser={currentUser} />} ></Route>
        <Route exact path={`${initialProjectDirectory}/vacations/calendar`} element={<VacationsCalendarPage currentUser={currentUser}/>} ></Route>
        <Route exact path={`${initialProjectDirectory}/vacations/listOfRequests`} element={<ListOfVacationRequestsPage currentUser={currentUser}/>} ></Route>
        {/* <Route exact path="/vacations/reports" element={<VacationsReportsPage />} ></Route> */}

        { currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("ROLE_MOD"))  && <>
            <Route exact path={`${initialProjectDirectory}/settings/organization`} element={<SettingsOrganizationPage currentUser={currentUser}/>} ></Route>
            <Route exact path={`${initialProjectDirectory}/settings/organization/addEmployee`} element={<SettingsOrganizationAddEmployeePage currentUser={currentUser}/>} ></Route>
            {currentUser && currentUser.roles.includes("ROLE_ADMIN") && <Route exact path={`${initialProjectDirectory}/settings/organization/notifications`} element={<SettingsOrganizationNotificationsPage currentUser={currentUser}/>} ></Route>}
            {currentUser && currentUser.roles.includes("ROLE_ADMIN") && <Route exact path={`${initialProjectDirectory}/settings/organization/vacations/limits`} element={<SettingsOrganizationVacationsLimitsPage currentUser={currentUser}/>}></Route>}
          </>
        }

        <Route exact path="*" element={<NotFound />}></Route>
      </Routes>
    </Suspense>
  </AbsolutePlatform>

  if(!authToken)return <Suspense fallback={<Loading />}>
  <Routes>
    <Route exact path={`${initialProjectDirectory}/logIn`} element={<LogInPage currentUserState={currentUserState}/>}></Route>
    <Route exact path={`${initialProjectDirectory}/logIn/resetPassword`} element={<LogInResetPasswordPage/>}></Route>
    <Route exact path="*" element={<NotFound />}></Route>
  </Routes>
</Suspense>
  
}