  import { Sidebar } from "react-pro-sidebar";
import { Menu } from "../../../shared/Menu";
import logo from "../../../shared/lib/img/Logo_BeUpHR.png";
import shortLogo from "../../../shared/lib/img/short_Logo_BeUpHR.png";
import "./style.css";
import { LanguageSwitcher } from "../../../shared/LanguageSwitcher/ui/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Menu as SettingsMenu } from "primereact/menu";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { initialProjectDirectory } from "../../../entities/initialProjectDirectory";
import { checkDisplay, logOut } from "../../../shared/lib";
import { useMemo } from "react";

export const SideBar = ({navigate,currentUser}) => {
  const { i18n } = useTranslation();
  
  const collapsed = useMemo(()=>(checkDisplay.width("phone") || checkDisplay.width("tablet") ||  checkDisplay.width("laptop"))?true:false,[])

  const settingsMenuItems = useMemo(()=>{
    const itemsArr = []
    if(currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("ROLE_MOD")))itemsArr.push({ label: i18n.t("repeatedWords.organization"), icon: "pi pi-users", command:()=>navigate(`${initialProjectDirectory}/settings/organization`)})
    itemsArr.push({ label: i18n.t("repeatedWords.logOut"), icon: "pi pi-sign-out", command:()=>logOut(navigate)})
    return itemsArr
  },[currentUser])
  
  const settingsMenuRef = useRef(null);

  return (
    <Sidebar className="sideBar" {...{collapsed}}>
      <div className="side_bar_logo_container">
        <Link to={`${initialProjectDirectory}/vacations/listOfRequests`}>
          {collapsed?<img src={shortLogo} className="short_logo" alt="BeUp HR"/>:<img src={logo} className="logo" alt="BeUp HR"/>}
        </Link>
      </div>

      <Menu i18n={i18n} />

      <SettingsMenu model={settingsMenuItems} popup ref={settingsMenuRef}/>
      <div className="side_bar_settings_section">
        <Button icon="pi pi-cog icon" severity="secondary" rounded text onClick={(event) => settingsMenuRef.current.toggle(event)}/>
        <LanguageSwitcher />
      </div>
    </Sidebar>
  );
};
