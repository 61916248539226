import { useForm, Controller } from "react-hook-form";
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { FieldError } from "../../../../shared/FieldError";
import { Link } from "react-router-dom";
import { initialProjectDirectory } from "../../../../entities/initialProjectDirectory";

export const ResetPasswordByEmail = ({ i18n, onSubmit, loading}) => {

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange",defaultValues:{email:""} });

  
  const { t } = i18n;							

  return (
    <form onSubmit={handleSubmit((fData)=>onSubmit(fData))}>
      <div className="form_fields_grid">
        <label className="align_self_center">Email *</label>
        <div className="form_input_container">
          <Controller name="email" control={control} rules={{required:true, pattern:/^[\w\.-]{1,64}@[a-zA-Z\d\.-]{2,}\.[a-zA-Z]{2,}$/}} render={({field})=><InputText maxLength={120} placeholder={`${t("repeatedWords.enter")} Email`} value={field.value} onChange={(e) => field.onChange(e.target.value)} />}/>
          <FieldError fieldErr={errors?.email} errPatternMessage={t("error.user.pattern.message")}/>
        </div>

        <Button className="submit_button" label={t("repeatedWords.reset")} {...(loading?{loading:true}:{})}/>
        <Link className="beUpColor" style={{alignSelf:"center",justifySelf:"end"}} to={`${initialProjectDirectory}/logIn`}>{t("pages.logIn.header.title")}</Link>
      </div>
    </form>
  );
};
