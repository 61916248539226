export const getOnSelectFunc = (totalSizeState, onChange) => (e) => {
  const [, setTotalSizeStateValue] = totalSizeState;

  let _totalSize = 0;
  const { files } = e;

  files.forEach((file) => {
    _totalSize += file.size;
  });

  if (onChange) onChange(e);
  setTotalSizeStateValue(_totalSize);
};
