export const getRolesSelectOptions = (currentUser,i18n) => {
  const { t } = i18n;

  const options = [{label:t("auth.roles.ROLE_USER"),value:"ROLE_USER"},{label:"Moderator",value:"ROLE_MOD"},{label:t("auth.roles.ROLE_ADMIN"),value:"ROLE_ADMIN"}]

//   if (currentUser.roles.includes("ROLE_ADMIN")) {
//     options.push({ label: "Moderator", value: "ROLE_MOD" });
//     options.push({ label: t("auth.roles.ROLE_ADMIN"), value: "ROLE_ADMIN" });
//   }

  return options;
};
