export const getFilterIntrefaceProps = (filterType, filterState) => {
  if (filterState) {
    switch (filterType) {
      case "row":
        return { filter: true, showFilterMenu: false, showClearButton: false };

      case "menu":
        return {
          filter: true,
          showApplyButton: false,
          showClearButton: false,
          showAddButton: false,
          showFilterMatchModes: false,
          showFilterMenuOptions: false,
        };
      default:
        break;
    }
  } else return {}
};
