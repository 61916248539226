export const parseObjectToFormDataFormat = (object) => {
  let bodyFormData = new FormData();

  for (let key in object) {
    if (object[key] instanceof FileList) {
      const fileList = object[key];

      for (let file of fileList) bodyFormData.append(`${key}[]`, file);

      continue;
    }

    bodyFormData.append(key, object[key]);
  }

  return bodyFormData;
};
