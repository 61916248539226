import { useEffect, useMemo, useState } from "react";
import { ActionVacationRequest as UI } from "./ui/ActionVacationRequest";
import { getElectedEmployeeUseEffectFunc, getPlannedVacationDatesArr, initValues } from "./lib";
import { api, getDateToSend } from "../../../shared/api";
import { toastMessageConfig } from "../../../entities/toastMessageConfig";
import { removeEmptyKeys } from "../../../shared/api/lib/removeEmptyKeys";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import { parseObjectToFormDataFormat } from "../../../shared/lib";
import { checkPeriodsForUpdatingRequest } from "./lib";

export const ActionVacationRequest = ({
  i18n,
  currentUser,
  className,
  type = "new",
  toastRef,
  defaultValues,
  electedEmployeeProp,
  dialogState,
  tableState
}) => {

  const  componentState = useState({ status: "waiting" })
  const [componentStateValue, setComponentStateValue] = componentState;

  const isInitialRender = useRef(true);

  const defaultValuesFormCopy= useMemo(()=>defaultValues?({...defaultValues}):undefined,[])
  if(defaultValuesFormCopy)getPlannedVacationDatesArr(defaultValuesFormCopy)

  const formState = useForm({ mode: "onChange", defaultValues: {...initValues,...defaultValuesFormCopy} })
  const {watch} = formState;
  
  const electedEmployeeIri = watch("employee");

  useEffect(() => {
    if (!isInitialRender.current && electedEmployeeIri) {
      getElectedEmployeeUseEffectFunc(toastRef,componentState,electedEmployeeIri);
    } else isInitialRender.current = false;
  }, [electedEmployeeIri]);

  const {t} = i18n

  const onSubmit = async (fData) => {

    setComponentStateValue((prevState)=>({ ...prevState,status: "loading" }));

    const { plannedVacationDatesArr, attachment } = fData;

    if(attachment.length){

      const postFileResponse = await api.vacations.files.post(parseObjectToFormDataFormat({file:attachment[0]}))

      const {warning,error} = postFileResponse

      if(warning){
        toastRef.current.show({ severity: 'warn', summary: warning.title, detail: warning.message,life:toastMessageConfig.life})
        return
      }

      if(error){
        toastRef.current.show({ severity: 'error', summary: error.title, detail: error.message,sticky:true })
        return
      }

      fData.file = postFileResponse
    }

    delete fData.attachment

    const dateNowObj = new Date()
    dateNowObj.setHours(0, 0, 0, 0,)

    const fDataDateFromObj = new Date(plannedVacationDatesArr[0])

    if(+dateNowObj > +fDataDateFromObj){
      toastRef.current.show({
        severity: "warn",
        summary: "Drogi Pracowniku!",
        detail: "Ten termin już upłynął. Wnioski muszą być wystawiane bezwzględnie przed urlopem.",
        life: toastMessageConfig.life,
      });
    }


    if (type === "update") {
      const checkResult = await checkPeriodsForUpdatingRequest(fData)

      if (checkResult?.warning) {
        toastRef.current.show({
          severity: "warn",
          summary: checkResult.warning.title,
          detail: checkResult.warning.message,
          life: toastMessageConfig.life,
        })
        setComponentStateValue((prevState) => ({ ...prevState, status: "waiting" }))
        const [, setInfoDialogStateValue] = dialogState
        const [, setTableStateValue] = tableState
  
        setInfoDialogStateValue({ visible: false })
        setTableStateValue((prevState) => ({ ...prevState, refreshesNumber: prevState.refreshesNumber + 1 }))
        return
      }

      if (checkResult?.error) {
        toastRef.current.show({
          severity: "error",
          summary: checkResult.error.title,
          detail: checkResult.error.message,
          sticky: true,
        })
        setComponentStateValue((prevState) => ({ ...prevState, status: "waiting" }))
        const [, setInfoDialogStateValue] = dialogState
        const [, setTableStateValue] = tableState
  
        setInfoDialogStateValue({ visible: false })
        setTableStateValue((prevState) => ({ ...prevState, refreshesNumber: prevState.refreshesNumber + 1 }))
        return
      }
    }

    fData.dateFrom = getDateToSend(new Date(plannedVacationDatesArr[0]));
    fData.dateTo = getDateToSend(new Date(plannedVacationDatesArr[1]));

    delete fData.plannedVacationDatesArr

    removeEmptyKeys(fData)
    
    const vacationActionResponse = await (type==="new"?api.vacations.post(fData):api.vacations.put(fData?.id,fData))

    if(type === "update"){
      const [,setInfoDialogStateValue] = dialogState
      const [,setTableStateValue] = tableState
      
      setInfoDialogStateValue({visible:false})
      setTableStateValue(prevState=>({...prevState,refreshesNumber:prevState.refreshesNumber + 1}))
    }

    const { warning, error } = vacationActionResponse;

    if (warning) {
      toastRef.current.show({
        severity: "warn",
        summary: warning.title,
        detail: warning.message,
        life: toastMessageConfig.life,
      });
      setComponentStateValue((prevState)=>({ ...prevState,status: "waiting" }));

      return;
    }

    if (error) {
      toastRef.current.show({
        severity: "error",
        summary: error.title,
        detail: error.message,
        sticky: true,
      });
      setComponentStateValue((prevState)=>({ ...prevState,status: "waiting" }));
      return;
    }

    toastRef.current.show({
      severity: "success",
      summary: t("repeatedWords.success"),
      detail: t("message.successfulOperation.message"),
      life: toastMessageConfig.life,
    });

    setComponentStateValue((prevState)=>({ ...prevState,status: "waiting" }));
  };

  if (componentStateValue.status === "loading") return (
    <UI
      formState={formState}
      defaultValuesFormCopy={defaultValuesFormCopy}
      currentUser={currentUser}
      i18n={i18n}
      toastRef={toastRef}
      className={className}
      type={type}
      onSubmit={onSubmit}
      loading
    />)

    if (componentStateValue.status === "waiting") return (
      <UI
        formState={formState}
        defaultValuesFormCopy={defaultValuesFormCopy}
        currentUser={currentUser}
        i18n={i18n}
        toastRef={toastRef}
        className={className}
        type={type}
        onSubmit={onSubmit}
        electedEmployee={componentStateValue?.data?.electedEmployee || electedEmployeeProp}
      />)
};
