export const getOnRemoveFunc = (fileUploadRef, totalSizeState, onChange) => (e) => {

  const [, setTotalSizeStateValue] = totalSizeState;

  const { file:fileToDelete } = e;

  if(onChange){
    const files = fileUploadRef.current.getFiles()
    const currentFilesArray = files.filter(file=>file.objectURL === fileToDelete.objectURL?false:true)

    const eventWithAdditionalData = {...e,files:currentFilesArray}
    onChange(eventWithAdditionalData)
  }

  setTotalSizeStateValue(prevState=>prevState - fileToDelete.size);
};
