import { vacationRequestStatusesIris } from "../../../../../entities/vacationRequestStatusesIris";

export const getFieldsConfigByAccess = (currentUser,defaultValuesFormCopy,type) =>{
    const config = {}

    if(currentUser && (!currentUser.roles.includes("ROLE_ADMIN") && !currentUser.roles.includes("ROLE_MOD"))){

        config.employee = {value:currentUser.employee["@id"],additional:currentUser.employee,disabled:true}
        if(type === "update")config.comment = {disabled:true}

    }else if(currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("ROLE_MOD"))){

        if(currentUser?.employee){
            if(type === "update" && currentUser.employee["@id"] !== defaultValuesFormCopy.employee){
                config.attachment = {disabled:true}
            }

            if(currentUser.roles.includes("ROLE_MOD")){
                config.employee = {value:currentUser.employee["@id"],disabled:true}
            }

        } else config.attachment = {disabled:true}
        
    }

    if( type ==="update" && defaultValuesFormCopy.status !== vacationRequestStatusesIris.planned){
        config.type = {disabled:true}
        config.plannedVacationDatesArr = {disabled:true}
    }

    return config
}