export const getDaysBetweenDates = (dates, weekendCounting = false) => {

  let daysNumber = 0

  if (!dates || !dates[1]) return daysNumber;

  const startDate = new Date(dates[0]);
  const endDate = new Date(dates[1]);

    for( let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1) ){

      if(!weekendCounting){
        const weekDayId = currentDate.getDay()
        if( weekDayId === 0 || weekDayId === 6 ) continue
      }
      
      daysNumber++

    }

  return daysNumber

};
