import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { useRef, useState } from 'react';
import "./style.css"
import { bytesToMegabytes } from '../../lib';
import { getOnSelectFunc, getOnRemoveFunc } from '../lib';

export const AdvancedFileUpload = ({i18n,additionalButtonsConfig = {upload:false,cancel:false},maxFileSize,accept,onChange,fileUploadProps = {}}) =>{

    const fileUploadRef = useRef(null);

    const totalSizeState = useState(0);
    const [totalSizeStateValue] = totalSizeState

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSizeStateValue / 10000;
        const formattedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSizeStateValue) : '0 B';

        return (
            <div className={`${className} AdvancedFileUpload_header`}>
                {chooseButton}
                {additionalButtonsConfig?.upload && uploadButton}
                {additionalButtonsConfig?.cancel && cancelButton}
                {maxFileSize && (
                    <div className="flex align-items-center gap-3 ml-auto">
                        <span>{formattedValue} / {bytesToMegabytes(maxFileSize)} MB</span>
                        <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                    </div>)}
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="AdvancedFileUpload_emptyTemplate">
                <i className="pi pi-image"></i>
                <h3>
                    {`${i18n.t("advancedFileUpload.emptyTemplate.title")} ${accept?("(" + accept + ")"):""}`}
                </h3>
            </div>
        );
    };

    return <FileUpload ref={fileUploadRef} onSelect={getOnSelectFunc(totalSizeState,onChange)} onRemove={getOnRemoveFunc(fileUploadRef,totalSizeState,onChange)} headerTemplate={headerTemplate} emptyTemplate={emptyTemplate} {...fileUploadProps} maxFileSize={maxFileSize} accept={accept} mode='advanced'/>
}