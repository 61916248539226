import { useState } from "react";
import { ResetPasswordByEmail as UI } from "./ui/ResetPasswordByEmail";
import { api } from "../../../shared/api";
import { toastMessageConfig } from "../../../entities/toastMessageConfig";

export const ResetPasswordByEmail = ({ i18n, toastRef}) => {
  const [componentStateValue, setComponentStateValue] = useState({ status: "waiting" });

  const getOnSubmit = (i18n,toastRef)=> async (fData) =>{

    const { t } = i18n;	

    setComponentStateValue({ status: "loading" });

    const changePasswordResponse = await api.users.changePasswordByEmail(fData)

    const { warning, error } = changePasswordResponse

    if(warning){
      toastRef.current.show({severity: "warn",summary: warning.title, detail: warning.message});
      setComponentStateValue({ status: "waiting" });
      return
    }

    if(error){
      toastRef.current.show({ severity: 'error', summary: error.title, detail: error.message,sticky:true })
      setComponentStateValue({ status: "waiting" });
      return
    }

    toastRef.current.show({ severity: 'success', summary: t("message.resetPasswordByEmailForm.success.title"), detail: t("message.resetPasswordByEmailForm.success.message"), life:toastMessageConfig.life})
    setComponentStateValue({ status: "waiting" });
  }

  if (componentStateValue.status === "loading") return <UI i18n={i18n} onSubmit={getOnSubmit(i18n,toastRef)} loading />;
  if (componentStateValue.status === "waiting") return <UI i18n={i18n} onSubmit={getOnSubmit(i18n,toastRef)}/>;


};
